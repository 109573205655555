import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { Button } from '../elements'
import { graphql } from 'gatsby'

const PBox = styled(AnimatedBox)`
max-width: 1400px;
margin: 0 auto;
padding-bottom: 3rem;
`

const PButton = styled(Button)<{ color: string }>`
background: ${props => (props.color === 'white' ? 'black' : props.color)};
`

const Info = ({data}) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  
  
  return (
    <Layout color='black'>
      <SEO title="Site Information | Conrad" desc="Repository of site information and resources." />
      <PBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]} >
        <h1>Site Information</h1>
        <h3>Resources 📚</h3>
        <p>
        This site was made possible with...
        </p>
        <ul>
          <li>Gatsby Starter Template: <a href="https://github.com/LekoArts/gatsby-starter-portfolio-jodie" target="_blank">LekoArts/gatsby-starter-portfolio-jodie</a></li>
          {/* <li>Notion Plugin: <a href="https://github.com/mayneyao/gatsby-starter-notion" target="_blank">mayneyao/gatsby-starter-notion</a></li> */}
          <li>Notion Plugin: <a href="https://github.com/orlowdev/gatsby-source-notion-api" target="_blank">orlowdev/gatsby-source-notion-api</a></li>
          <li>Blog Components: <a href="https://github.com/scttcper/gatsby-casper" target="_blank">scttcper/gatsby-casper</a></li>
        </ul>
        
        {/* <h3>Known Bugs 🐞</h3>
        <p>
        Notion.so integration with gatsby is experimental - let me know if you notice any bugs!
        </p>
        <ul>
          <li>Blog images may expire and not display</li>
        </ul> */}

        <h3>Latest Updates ✨</h3>
        <p>
        Site was last published on: {new Date(data.site.buildTime).toLocaleString()}.
        </p>
        <ul>
      {data.allGitCommit.edges.map(({ node: commit }) => (
        <li key={commit.id}>
          <code>
            <strong>{commit.hash.substring(0, 8)}</strong> (
            {new Date(commit.date).toLocaleString()})
          </code>
          <br />
          {commit.message}
          <br />
          {commit.diff && "Changed files:"}
          {commit.diff && (
            <ul>
              {commit.diff.files.map(({ file }, index) => (
                <li key={index}>
                  <code>{file}</code>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
       
      <PBox style={{ textAlign: 'center' }} py={1} px={[6, 6, 8, 10]}>
        <h2>Have Any Suggestions?</h2>
        <a href="mailto:lets.talk@conradlin.com" target="_blank">
        <PButton color="white" py={4} px={8}>
          Let Me Know
        </PButton>
        </a>
        </PBox>
      </PBox>
    </Layout>
  )
}

export default Info
export const query = graphql`
  {
    allGitCommit(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          hash
          message
          date
          diff {
            files {
              file
            }
          }
        }
      }
      totalCount
    }
    site {
      buildTime
    }
  }
`